
import "./bootstrap";

import React from 'react';
import PropTypes from 'prop-types';
import CssBaseline from '@mui/material/CssBaseline';
import Paper from '@mui/material/Paper';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import withStyles from '@mui/styles/withStyles';

import CircularProgress from '@mui/material/CircularProgress';
// import Logo from './assets/fire1310';
import { ReactComponent as Logo } from './assets/FF1310.svg';



const styles = theme => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing(3) * 3,
    marginRight: theme.spacing(3) * 3,
    [theme.breakpoints.up(400 + theme.spacing(3) * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing(2)} ${theme.spacing(3)} ${theme.spacing(3)}`,
  },
  avatar: {
    margin: theme.spacing(),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(),
  },
  submit: {
    marginTop: theme.spacing(3),
  },
});


class LoadingLogin extends React.Component {

  render() {
    const { classes } = this.props;

    return (
      <div>
        <CssBaseline />
        <AppBar position="static" color="primary">
          <Toolbar>
            <Logo height="60px" style={{marginTop: 7.5}}/>
          </Toolbar>
        </AppBar>
        <main className={classes.main}>
          <Paper className={classes.paper}>
            <CircularProgress />
          </Paper>
        </main>
      </div>
      )
  }
}

LoadingLogin.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(LoadingLogin);
